import $ from 'jquery';
import 'jquery-form';
import 'bootstrap';

function ajaxForm($modal, $form, callback) {
    $form.ajaxForm({success: function (data) {
        let $newForm = $(data).find('form.ajaxForm');
        if ($newForm.length > 0) {
            $form.replaceWith($newForm);
            ajaxForm($modal, $newForm, callback);
        } else {
            $modal.modal('hide');
            callback();
        }
    }});
}

function makeModalForm(content, callback) {
    let $content = $(content);

    $('body').append($content);
    $content.modal();
    let $form = $content.find('form.ajaxForm');
    ajaxForm($content, $form, callback);
}

export function callAjaxModal(uri, callback) {
    $.ajax(uri, {type: 'GET', success: function(data) {
        if (data) {
            makeModalForm(data, callback);
        } else {
            callback() //todo: decide what to send to callback here
        }
    }});
}
