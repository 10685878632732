import $ from 'jquery';
import 'bootstrap';
import 'basket.js';
import 'jquery.fancybox.min';
import '../../Public/Styles/frontend.css';
import '../../Public/Styles/main.scss';
import '../../Public/Styles/jquery.fancybox.min.css';
import 'myAccount';
import 'crud-datatables';
import 'bootstrap-datepicker-webpack';
import 'password-validator';

__webpack_public_path__ = dieEchte.assetUri;

$.ajaxSetup({ cache: false });

jQuery(document).ready(function($){
    let passwordValidationIsActive = true;

    //if you change this breakpoint in the style.css file (or _layout.scss if you use SASS), don't forget to update this value as well
    const passwordValidator = require('password-validator');

    const $cart_trigger = $('*[data-action="sliding-basket"]'),
        $slidingBasket = $('#slidingBasket'),
        $shadow_layer = $('#iw-shadow-layer'),
        $primary_header = $('.header__primary'),
        $secondary_header = $('.header__secondary'),
        $scrollToActions = $('*[data-action="scroll-to"]'),
        $acceptCookiesAction = $('#accept-cookies'),
        $toggleCollapsible = $('*[data-action="toggle-collapsible"]'),
        $productsCollapsible = $('#productsCollapsible'),
        $cookie_hint = $('.cookie-hint'),
        currentLoginSelection = localStorage.getItem('currentLoginSelection'),
        openSlidingBasket = localStorage.getItem('open-sliding-basket'),
        keepSlidingBasket = localStorage.getItem('keep-sliding-basket'),
        $passwordField = $('input[data-validate-password="true"]'),
        $scrollToTop = $('.back-to-top');


    const passwordSchema = new passwordValidator();
    passwordSchema
        .is().min(8)                                    // Minimum length 8
        .is().max(100)                                  // Maximum length 100
        .has().uppercase()                              // Must have uppercase letters
        .has().lowercase()                              // Must have lowercase letters
        .has().digits()                                 // Must have digits
        .has().not().spaces();                          // Should not have spaces

    //open cart
    $cart_trigger.on('click', function(event){
        event.preventDefault();
        //close lateral menu (if it's open)
        toggle_panel_visibility($slidingBasket, $shadow_layer, $('body'));
    });

    // smooth scroll
    $scrollToActions.on('click', function(event) {
        event.preventDefault();
        const target = $(this).data('target');
        scrollTo($('#' + target));
    });

    //close lateral cart or lateral menu
    $shadow_layer.on('click', function(){
        closeSlidingBasket($shadow_layer, $slidingBasket);
    });

    $('*[data-action="close-sliding-basket"]').on('click', function(event) {
        event.preventDefault();
        closeSlidingBasket($shadow_layer, $slidingBasket);
    });

    if (!localStorage.getItem('cookies-accepted')) {
        $cookie_hint.show();
    }

    // close cookie hint and save to LS
    $acceptCookiesAction.on('click', function() {
        $cookie_hint.hide();
        localStorage.setItem('cookies-accepted', true);
    });

    // secondary header with menu is fixed when scrolling down
    $(window).on('scroll', function() {
        const scrollPos = $(document).scrollTop();
        const primaryHeaderHeight = $primary_header.height();
        if (scrollPos > primaryHeaderHeight) {
            $secondary_header.addClass('header__secondary--fixed');
            $('body main').css('margin-top', $secondary_header.height())
        } else {
            $secondary_header.removeClass('header__secondary--fixed');
            $('body main').css('margin-top', 0)
        }

        if (scrollPos > 400) {
            $scrollToTop.show();
        } else {
            $scrollToTop.hide();
        }
    });

    // toggle collapsible
    $toggleCollapsible.on('click', function(event) {
        event.preventDefault();
        const $target = $($(this).attr('href'));
        $target.collapse('toggle');
    });

    // animate chevron when collapsible is opened
    $('.collapse').on('show.bs.collapse', function () {
        const $chevron = $(this).closest('.collapsible-dropdown').find('.collapsible-dropdown__icon');
        if($chevron) {
            $chevron.removeClass('turn-back').addClass('turn');
        }
    });

    // animate chevron when collapsible is closed
    $('.collapse').on('hide.bs.collapse', function () {
        const $chevron = $(this).closest('.collapsible-dropdown').find('.collapsible-dropdown__icon');
        if ($chevron) {
            $chevron.removeClass('turn').addClass('turn-back');
        }
    });

    // update product when selected from collapsible
    $productsCollapsible.find('a').on('click', function(event) {
        event.preventDefault();
        $productsCollapsible.collapse('toggle');
        handleProduct(this);
    });

    // used for checkout to give it a one-pager feeling
    if ($('*[data-scroll-to="here"]').length > 0) {
        scrollTo($('*[data-scroll-to="here"]'));
    }

    // toggle register/login during checkout
    if (currentLoginSelection) {
        $('input[data-target=' + currentLoginSelection + ']').prop("checked", true);
        $('#' + currentLoginSelection).show();
        if (currentLoginSelection === 'checkout__create-account') {
            passwordValidationIsActive = true
        }
    }

    $('.login-option input[name=login-options]').on('change', function() {
        const target = $(this).data('target');
        $('#checkout-login').slideUp();

        $('#' + target).slideDown();
        localStorage.setItem('currentLoginSelection', target);
    });

    // mobile - checkout - set back-link
    const lastActiveStep = $('.progress-bar .progress-bar__step--was-active').last();
    if (lastActiveStep) {
        $('.progress-bar .progress-bar__step--back a').attr('href', $(lastActiveStep).find('a').attr('href'))
    }

    $.fn.datepicker.dates['de-DE'] = {
        clear: "Löschen",
        prevText: "&#x3C;Zurück",
        nextText: "Vor&#x3E;",
        today: "Heute",
        months: [ "Januar","Februar","März","April","Mai","Juni",
            "Juli","August","September","Oktober","November","Dezember" ],
        monthsShort: [ "Jan","Feb","Mär","Apr","Mai","Jun",
            "Jul","Aug","Sep","Okt","Nov","Dez" ],
        days: [ "Sonntag","Montag","Dienstag","Mittwoch","Donnerstag","Freitag","Samstag" ],
        daysShort: [ "So","Mo","Di","Mi","Do","Fr","Sa" ],
        daysMin: [ "So","Mo","Di","Mi","Do","Fr","Sa" ],
    };


    // backend statistics datepicker
    $('.input-daterange').datepicker({
        weekStart: 1,
        todayBtn: 'linked',
        clearBtn: true,
        language: 'de-DE',
        multidate: false,
        toggleActive: true,
        format: 'dd.mm.yyyy',
        todayHighlight: true
    });

    $('#reportingAction').submit(function(e) {
        if (!validDate($('#reportingStart').val()) || !validDate($('#reportingEnd').val())) {
            e.preventDefault();
            //TODO show real validation error message instead of alert.
            alert('Bitte geben Sie ein gültiges Datumsformat ein.');
        }
    });

    // prevent click event for links with popover tooltip
    // for some reason fancyBox was triggered
    $('[data-rel=popover]').popover();
    $('a[data-rel="popover"]').on('click', function(event) {
        event.preventDefault();
    });

    $('.print').click(function() {
        window.print();
        return false;
    });

    $('.f3-form-error').parents('.form-group').addClass('has-error');

    let warned = false;
    $('.emailRepetitionCopy').bind('copy', function() { return false; });

    $('.emailRepetitionCopy').bind('paste', function(e) { if (! warned) { warned=true; alert(unescape(translations['layouts.defaultInformation']));} e.stopPropagation(); return false; });

    $('.selectQuantity').change(function(){
        if($(this).val() == 0) {
            $(this).closest('.basket__position').find('.basket__position-remove').submit();
        }
        else {
            localStorage.setItem('open-sliding-basket', 'true');
            localStorage.removeItem('keep-sliding-basket');
            $(this).closest('form').submit();
        }
    });

    $('#slidingBasket').find('.selectQuantity').on('change', function (e) {
        localStorage.setItem('open-sliding-basket', 'true');
        localStorage.setItem('keep-sliding-basket', 'true');
    });

    $('.basket__position').each(function (index, position) {
        $(position).find('.selectQuantity').val($(position).find('.positionCount').val());
    });

    const stringToFloat = function (stringVal) {
        stringVal = stringVal.replace(',','.');
        return parseFloat(stringVal).toFixed(2);
    };

    const floatToFixedString = function (floatVal) {
        floatVal = floatVal.toString();
        floatVal = floatVal.replace('.',',');
        return floatVal;
    };

    let oldCount = $('#count').val();
    $(document).on('change', '#count', function () {
        let count = $(this).val();
        let productWeight = stringToFloat($('*[data-update-weight="true"]')[0].innerHTML);
        let pricePerKg = stringToFloat($('*[data-update-priceperkg="true"]')[0].innerHTML);
        let minPosQuantity = parseInt($('#minPosQuantity').data('content'));

        if (! Number.isNaN(count) && count > 0 && count * productWeight <= 48 && minPosQuantity === 1) {
            $('*[data-update-price="true"]')[0].innerHTML = floatToFixedString((count * pricePerKg * productWeight).toFixed(2));
            oldCount = $(this).val();
        } else if (! Number.isNaN(count) && count > 0 && count * 0.2 <= 48 && minPosQuantity === 5) {
            $('*[data-update-price="true"]').text(floatToFixedString((count * pricePerKg).toFixed(2)));
            oldCount = $(this).val();
        }

        $('#calculate').hide();
    });

    $(document).on('keyup', '#count', function () {
        if(oldCount != $('#count').val()) {
            $('#calculate').css('display', 'inline-block');
        } else {
            $('#calculate').hide();
        }
    });

    $('#deliveryAddressControl').on('hidden.bs.collapse', function() {
        $('input, select, textarea', this).attr('disabled', 'disabled');
    }).on('show.bs.collapse', function() {
        $('input, select, textarea', this).removeAttr('disabled');
        $('.disabled').attr('disabled', 'disabled');
    });

    $('#toggleDeliveryAddress').on('change', function() {
        $('#deliveryAddressControl').collapse($(this).is(':checked') ? 'show' : 'hide');
    }).change();

    $('#productsCollapsible a').on('click', function () {
        $('#count').val(1);
    });

    $passwordField.closest('form').on('submit', function(event) {
        if (!passwordValidationIsActive) {
            return
        }
        const currentPassword = $(this).find('#password').val().toString();
        const passwordRepetition = $(this).find('#passwordRepetition').val().toString();
        const passwordIsValid = passwordSchema.validate(currentPassword);
        const passwordIsTheSame = currentPassword === passwordRepetition;
        const $passwordFeedback = $(this).find('.invalid-feedback--password');

        if(!passwordIsValid || !passwordIsTheSame) {
            event.preventDefault();
            $passwordFeedback.show();
            if (!passwordIsTheSame) {
                $passwordFeedback.text('Passwörter stimmen nicht überein.')
            }
            if (!passwordIsValid) {
                $passwordFeedback.text('Passwort entspricht nicht unseren Sicherheitsrichtlinien.')
            }

            scrollTo($passwordFeedback);
        } else {
            $passwordFeedback.hide();
        }
    });

    $(document).ready(function() {
        $('.autofocus').first().focus();
    });

    $scrollToTop.on('click', function() {
       scrollTo($('body'))
    });

    if(!$('#newAccountCheckbox').prop('checked')) {
        passwordValidationIsActive = false
    }
    $('#newAccountCheckbox').on('change', function() {
        $('#checkout__create-account').fadeToggle();

        if ($(this).prop('checked')) {
            localStorage.setItem('currentLoginSelection', 'checkout__create-account');
            passwordValidationIsActive = true
        } else {
            localStorage.removeItem('currentLoginSelection');
            passwordValidationIsActive = false
        }
    });

    $('a#toggleProductsCollapsible[data-scroll]').click(function(e) {
        e.preventDefault();

        let target = ( '#' + $(this).data('scroll') );
        let $target = $(target);

        $('#slidingBasket').stop().animate({
            'scrollTop': $target.offset().top
        }, 400, 'swing');
    });

    $('#commentText').keyup(function () {
        $('.wordCount').empty().prepend($('#commentText').attr('maxlength') - ($('#commentText').val().length))
    }).keyup();

    $('#infoText').keyup(function () {
        $('.wordCount').empty().prepend($('#infoText').attr('maxlength') - ($('#infoText').val().length))
    }).keyup();

    // open sliding basket on add to basket
    $('#add-to-basket-form').on('submit', function(event) {
       localStorage.setItem('open-sliding-basket', 'true');
    });

    if(!keepSlidingBasket && $('.errors').length > 0) {
        localStorage.removeItem('open-sliding-basket');
    }

    if(keepSlidingBasket || (openSlidingBasket && $('.errors').length === 0)) {
        toggle_panel_visibility($slidingBasket, $shadow_layer, $('body'));
        localStorage.removeItem('open-sliding-basket');
        localStorage.removeItem('keep-sliding-basket');
    }

    if ($('.errors.list-unstyled').length) {
        $([document.documentElement, document.body]).animate({
            scrollTop: $('.errors.list-unstyled').first().offset().top - 200
        }, 500);
    }

    $('.buy-now-trigger').on('click', function() {
        if (checkBuyNowState()) {
            $('.checkout__actions.order-now-button .button').removeAttr('disabled').removeClass('button--is-disabled');
        } else {
            $('.checkout__actions.order-now-button .button').attr('disabled', true).addClass('button--is-disabled');
        }
    });

    $('#createAccount').change(function() {
        if ($('#passwordForm').length) {
            if ($('#createAccount').prop('checked')) {
                $('#passwordFormFields').slideDown(200);
                $('.checkout__actions.order-now-button .button').attr('disabled', true).addClass('button--is-disabled');
            } else {
                $('#passwordFormFields').slideUp(200);
                if (checkBuyNowState()) {
                    $('.checkout__actions.order-now-button .button').removeAttr('disabled').removeClass('button--is-disabled');
                } else {
                    $('.checkout__actions.order-now-button .button').attr('disabled', true).addClass('button--is-disabled');
                }
            }
        }
    });

    $('#passwordSubmit').click(function() {
        $('#passwordForm').submit();
    });

    $('#passwordField').change(function() {
        $('#password').val($(this).val());
    });

    $('#orderForm').submit(function(e) {
        $('#sendToPaymentButton').prop('disabled', true);
    });
});

$(window).on('load', function() {
    if ($('#addToBasketProduct').length) {
        let entries = $('#productsCollapsible').find('a');

        entries.each(function(index, element) {
            if ($(element).data('id') === $('#addToBasketProduct').val()) {
                handleProduct(element);
            }
        });
    }
});

function handleProduct(element) {
    let price = $(element).data('price');
    let weight = $(element).data('weight');
    let artno = $(element).data('artno');
    let priceperkg = $(element).data('priceperkg');

    const $toggleCollapsible = $('*[data-action="toggle-collapsible"]');

    $('*[data-update-price="true"]').text(price);
    $('*[data-update-weight="true"]').text(weight);
    $('*[data-update-artno="true"]').text(artno);
    $('*[data-update-priceperkg="true"]').text(priceperkg);
    $('#addToBasketProduct').val($(element).data('id'));
    $('#addToBasketProductWeight').val($(element).data('weight'));
    $toggleCollapsible.find('span').text($(element).text());
}

function checkBuyNowState() {
    if ($('#tos').prop('checked') === true &&
        $('#withdrawal').prop('checked') === true &&
        $('#dataprivacy').prop('checked') === true &&
        $("input[name='paymentAdapter']:checked").length === 1
    ) {
        return true;
    }

    return false;
}

function validDate(str) {
    if (str === undefined) {
        return false;
    }

    const t = str.match(/^(\d{2})\.(\d{2})\.(\d{4})$/);
    if(t !== null){
        const d = +t[1], m = +t[2], y = +t[3];
        const date = new Date(y, m - 1, d);
        if(date.getFullYear() === y && date.getMonth() === m - 1) {
            return true;
        }
    }

    return false;
}

function closeSlidingBasket ($shadow_layer, $slidingBasket) {
    $shadow_layer.removeClass('is-visible');
    // firefox transitions break when parent overflow is changed, so we need to wait for the end of the trasition to give the body an overflow hidden
    if( $slidingBasket.hasClass('speed-in') ) {
        $slidingBasket.removeClass('speed-in').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function() {
            $('body').removeClass('overflow-hidden');
        });
    }
}

function toggle_panel_visibility ($lateral_panel, $background_layer, $body) {
    if( $lateral_panel.hasClass('speed-in') ) {
        // firefox transitions break when parent overflow is changed, so we need to wait for the end of the trasition to give the body an overflow hidden
        $lateral_panel.removeClass('speed-in').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function() {
            $body.removeClass('overflow-hidden');
        });
        $background_layer.removeClass('is-visible');

    } else {
        $lateral_panel.addClass('speed-in').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function() {
            $body.addClass('overflow-hidden');
        });
        $background_layer.addClass('is-visible');
    }
}

function scrollTo (target) {
    $('html, body').animate({
        scrollTop: target.offset().top - $('.header__secondary').height()
    }, 250)
}
