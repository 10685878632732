(function ( factory ) {
    "use strict";
    if ( typeof define === "function" && define.amd ) {
        // AMD. Register as an anonymous module.
        define([
        ], factory);
    } else {
        // Browser globals
        factory();
    }
}(function ( ) {
    var inwebs = inwebs || {};
    window.inwebs = inwebs;

    inwebs.currencyFormat = function (value, currency) {
        if (!currency) {
            currency = '€';
        }

        var result = '';

        value = value.replace(',', '.');

        if (isNaN(value)) {
            return "0,00";
        }

        if (value.match(/\D\d$/)) {
            value += '0';
        }
        if (value.match(/\D$/)) {
            value += '00';
        }
        if (!value.match(/\d*[.,]\d*/)) {
            value += ',';
            value += '00';
        }
        value = value.replace('.', ',');
        return value;
    };

    inwebs.updateAmount = function (element, currency) {
        var value = element.val();
        result = inwebs.currencyFormat(value, currency);
        element.val(result);
    };

    inwebs.delay = (function () {
        var timer = {};
        return function (callback, ms) {
            if (timer[callback]) {
                clearTimeout(timer[callback]);
            }
            timer[callback] = setTimeout(callback, ms);
        };
    })();
}));
