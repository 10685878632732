import $ from 'jquery';

$(function() {
    const currentSectionFromLocalStorage = localStorage.getItem('myAccountSection');
    if (currentSectionFromLocalStorage) {
        const currentSection = $('#' + currentSectionFromLocalStorage);
        $(currentSection).find('form').show();
        $(currentSection).find('.my-account__section__read-only').hide();
        scrollTo($(currentSection));
    }

    $('.my-account__section a[data-action="toggle-myaccount-edit"]').click(function(e) {
        e.preventDefault();
        const currentSection = $(this).closest('.my-account__section');
        localStorage.setItem('myAccountSection', currentSection.attr('id'));
        $(currentSection).find('form').slideDown();
        $(currentSection).find('.my-account__section__read-only').slideUp();
    });

    $('.my-orders a[data-toggle="collapse"]').on('click', function(e) {
        e.preventDefault();

        const target = $(this).data('target');
        $(target).collapse();
    });

    $('#addressForm').submit(function(e) {
        $('#registerSubmitButton').prop('disabled', true);
    });
});
