import $ from 'jquery';
import 'datatables.net-bs4';
import 'datatables.net-select-bs4';
import {callAjaxModal} from './ajax-modal';

$.fn.dataTable.ext.buttons.action = {
    action: function ( e, dt, node, config ) {
        let uri = $(dt.table().node()).data(config.uriData);
        callAjaxModal(uri, function() {dt.ajax.reload();});
    },
};
$.fn.dataTable.ext.buttons.actionSelected = {
    extend: 'selected',
    action: function ( e, dt, node, config ) {
        let identity = dt.rows({selected: true}).data()[0].__identity,
            uri = $(dt.table().node()).data(config.uriData).replace('subject', identity);

        callAjaxModal(uri, function() {dt.ajax.reload();});
    },
};

//TODO: CSRF - Handling and POST for changing data (like remove)
$(function() {
    var $orderTable = $('#orderTable');
    var orderTableActionClass = 'orderTableAction';
    var orderDetailsLinkClass = 'orderDetailsLink';

    function getLinkForOrder(uri, data) {
        return $orderTable.data(uri).replace('ORDER', data);
    }

    function getModalOption(uri, text, data) {
        return $('<option />', {
            class: 'loadModal',
            'data-modal': getLinkForOrder(uri, data),
            text: text
        });
    }

    function getActionOption(uri, text, data) {
        return $('<option />', {
            'data-action': getLinkForOrder(uri, data),
            text: text
        });
    }

    function getModalAction(uri, text, data, imageUri) {
        if (imageUri !== undefined) {
            return $('<img />', {
                class: 'loadModal',
                'data-modal': getLinkForOrder(uri, data),
                text: text,
                src: getLinkForOrder(imageUri, data.number)
            });
        } else {
            return $('<span />', {
                class: 'loadModal',
                'data-modal': getLinkForOrder(uri, data),
                text: text
            });
        }
    }

    function getGlyphicon(type, title) {
        return $('<span />', {
            class: 'fa fa-' + type,
            title: title
        });
    }

    function translate(type, id) {
        var translationData = $orderTable.data('translations-' + type.toLowerCase());
        if (translationData !== undefined) {
            return translationData[id];
        } else {
            return id;
        }
    }

    $.extend($.fn.dataTableExt.oStdClasses, {
        sLengthSelect: 'form-control',
        sFilterInput: 'form-control'
    });

    Number.prototype.formatMoney = function(c, d, t){
        var n = this,
            c = isNaN(c = Math.abs(c)) ? 2 : c,
            d = d === undefined ? "." : d,
            t = t === undefined ? "," : t,
            s = n < 0 ? "-" : "",
            i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
            j = (j = i.length) > 3 ? j % 3 : 0;
        return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
    };

    $orderTable.DataTable({
        language: {
            lengthMenu: 'Anzahl pro Seite: _MENU_',
            search: 'Suche:',
            emptyTable: 'Keine Bestellungen vorhanden.',
            info: '_START_ bis _END_ von _TOTAL_',
            infoEmpty: '0',
            infoFiltered: '(gefiltert von insgesamt _MAX_)',
            loadingRecords: 'Lade...',
            zeroRecords: 'Keine passenden Bestellungen gefunden.',
            paginate: {
                first: 'Erste',
                last: 'Letzte',
                next: 'Nächste',
                previous: 'Vorherige'
            },
            aria: {
                sortAscending: ': aktivieren, um die Spalte aufsteigend zu sortieren',
                sortDescending: ': aktivieren, um die Spalte absteigend zu sortieren'
            },
            select: {
                rows: {
                    _: "%d Zeile(n) ausgewählt",
                    0: "Klicken um auszuwählen",
                }
            }
        },
        aaSorting : [[1, 'desc']],
        bSort: true,
        iDisplayLength: 10,
        aLengthMenu: [1, 5, 10, 25, 50, 100],
        scrollX: true,

        dom: "<'row'<'col-md-3'B><'col-sm-12 col-md-3'l><'col-sm-12 col-md-6'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
        select: 'single',
        columnDefs: [
            {
                targets: 'render-action',
                render: function(data, type, row) {
                    var options = [];

                    switch (row['state']) {
                        case 'PAID':
                            options.push(getModalOption('markorderpendingUri', 'Bezahlung zurückziehen', data));
                            options.push(getModalOption('markordercanceledUri', 'Stornieren', data));

                            break;
                        case 'PENDING':
                            if (row.payment.adapter.name !== 'PayPal') {
                                options.push(getModalOption('markorderpaidUri', 'Bezahlen', data));
                            }
                            options.push(getModalOption('markordercanceledUri', 'Stornieren', data));

                            break;
                        case 'CANCELED':
                            options.push(getModalOption('markorderpaidUri', 'Stornierung zurückziehen (bezahlt)', data));

                            if (row.payment.adapter.name !== 'PayPal') {
                                options.push(getModalOption('markorderpendingUri', 'Stornierung zurückziehen (unbezahlt)', data));
                            }

                            break;
                        case 'COMPLETED':
                            options.push(getModalOption('markordercanceledUri', 'Stornieren', data));

                            break;
                    }

                    if (options.length > 0) {
                        var $select = $('<select />', {
                            class: orderTableActionClass + ' noAutoSelect2'
                        });

                        options.unshift(
                            $('<option />', {
                                text: 'Aktion wählen'
                            })
                        );

                        options.forEach(function(option) {
                            $select.append(option);
                        });

                        return $select.prop('outerHTML');
                    }
                }
            },
            {
                targets: 'render-details',
                render: function(data) {
                    return $('<a />', {
                        text: data,
                        class: orderDetailsLinkClass,
                        href: getLinkForOrder('detailsUri', data)
                    }).prop('outerHTML');
                }
            },
            {
                targets: 'render-state',
                render: function(data) {
                    return translate('state', data);
                }
            },
            {
                targets: 'render-paymentname',
                render: function(data) {
                    return translate('paymentname', data);
                }
            },
            {
                targets: 'render-comment',
                render: function(data) {
                    if (data) {
                        return 'Ja';
                    } else {
                        return 'Nein';
                    }
                }
            },
            {
                targets: 'render-datetime',
                render: function(data) {
                    var date = new Date(Date.parse(data));
                    return date.toLocaleString('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric'});
                }
            },
            {
                targets: 'render-currency',
                render: function(data) {
                    return (data).formatMoney(2, ',', '.') + '€';
                }
            },
            {
                targets: 'render-deliveryslip',
                render: function(uri, text, data) {
                    return $('<a />', {
                        html: '<img src="' + getLinkForOrder('deliverySlipImageUri', data.number) + '">',
                        href: getLinkForOrder('deliverySlipUri', data.number),
                        target: '_blank'
                    }).prop('outerHTML');
                }
            },
            {
                targets: 'render-deliveryInformation',
                render: function(uri, text, data) {
                    return $('<a />', {
                        html: getModalAction('deliveryInformationExportModalUri', '', data.number, 'deliveryInformationImageUri'),
                        href: 'javascript:void(null)'
                    }).prop('outerHTML');
                }
            },
            {
                targets: 'render-trackingLink',
                render: function(uri, text, data) {
                    let container = $('<div />');
                    if(data.shippingNumbers) {
                        for (let i = 0; i < data.shippingNumbers.length; i++) {
                            container.append('<a href="https://nolp.dhl.de/nextt-online-public/set_identcodes.do?lang=de&idc=' + data.shippingNumbers[i] + '"><img src="' + getLinkForOrder('trackingLinkImageUri', data.number) + '"></a>');
                        }
                    }
                    return container.prop('outerHTML');
                }
            }
        ],
        initComplete: function() {
            this.api().columns().every(function() {
                let column = this;
                let $headerColumn = $(column.header());
                let $footerColumn = $(column.footer());
                let columnName = $headerColumn.text().trim();
                let $searchProvider = $headerColumn.data('search-provider');

                if ($searchProvider === null || typeof $searchProvider === 'undefined') {
                    return;
                }

                switch ($searchProvider.type) {
                    case 'input':
                        $('<input />', {
                            type: 'text',
                            placeholder: 'Suche ' + columnName
                        }).appendTo($footerColumn.empty());

                        $footerColumn.find('input').on('keyup change', function() {
                            column.search($(this).val()).draw();
                        });
                        break;
                    case 'select':
                        let select = $('<select />')
                            .append($('<option />', {
                                value: '',
                                text: columnName + ' filtern...'
                            }))
                            .appendTo($footerColumn.empty())
                            .on('change', function() {
                                let val = $(this).val();

                                column.search(val ? '^' + val + '$' : '', true).draw();
                            });

                        const options = $searchProvider.configuration.options;
                        if (!$.isEmptyObject(options)) {
                            for (let property in options) {
                                select.append($('<option />', {
                                    value: property,
                                    text: translate($headerColumn.data('data'), property)
                                }));
                            }
                        }
                        break;
                }
            });

            $(window).resize();
        }
    });

    $orderTable.on('change', '.' + orderTableActionClass, function() {
        let $selectedOption = $(this).find('option:selected');

        if ($selectedOption.data('modal')) {
            $selectedOption.trigger('click');

            $(this).val($(this).find('option:first').val());
        } else if ($selectedOption.data('action')) {
            window.location = $selectedOption.data('action');
        }
    });

    $orderTable.on('click', '.' + orderDetailsLinkClass, function(e) {
        e.preventDefault();

        let $activeOrderDetailsLink = $(this);
        let $orderDetailsContainer = $('.orderDetailsContainer');

        $orderDetailsContainer.html(
            $('<img />', {
                src: '/_Resources/Static/Packages/Inwebs.DieEchte/Images/loader.gif'
            }).prop('outerHTML')
        );
        $orderTable.find('.orderDetailsLink').parents('tr').removeClass('active');

        $('html, body').animate({scrollTop: $orderDetailsContainer.offset().top}, 1000);

        $.ajax({
            url: $activeOrderDetailsLink.attr('href')
        }).done(function(data) {
            $orderDetailsContainer.html(data);
            $activeOrderDetailsLink.parents('tr').addClass('active');
        });
    });

    $('body').prepend('<div id="modal" class="modal fade" tabindex="-1" role="dialog"></div>');

    $(document).on('click', '.loadModal', function() {
        $('#modal').load($(this).data('modal'), function(content, status) {
            if (status === 'error') {
                $('#modal').html('<div class="modal-dialog"><div class="modal-content"><div class="modal-body">Error while loading</div></div></div>');
            }

            $('#modal').modal({show:true});
        });
    });

    $(document).on('shown.bs.modal', function (e) {
        if ($('#kgLeft') !== undefined && $('#kgLeft').html() !== '') {
            calculateKgLeft();
        }
    });

    $(document).on('change', '.packageWeight', function() {
        $('#kgLeft').html(calculateKgLeft($(this)));
    });

    function calculateKgLeft() {
        let kgLeft = $('#orderWeight').html();

        if ($('#package1').val() !== undefined && $('#package1').val() !== '') {
            let package1 = $('#package1').val().replace(',', '.');
            if ($.isNumeric(package1)) {
                kgLeft = $('#orderWeight').html() - package1;
            }
        }

        if ($('#package2').val() !== undefined && $('#package2').val() !== '') {
            let package2 = $('#package2').val().replace(',', '.');
            if ($.isNumeric(package2)) {
                kgLeft -= package2;
            }
        }

        if ($('#package3').val() !== undefined && $('#package3').val() !== '') {
            let package3 = $('#package3').val().replace(',', '.');
            if ($.isNumeric(package3)) {
                kgLeft -= package3;
            }
        }

        if (kgLeft !== undefined) {
            $('#kgLeft').html((Math.round(kgLeft * 100) / 100).toString().replace('.', ','));
        }
    }

    $(document).on('click', '#printShippingLabelButton', function(e) {
        $(this).hide();
        $('#printShippingLabelSpinner').show();
    });

    $(document).on('click', '#printSplitLabelsButton', function(e) {
        $(this).hide();
        $('#printSplitLabelsSpinner').show();
    });
});
